import prevNextButton from "flickity/js/prev-next-button";

export default function MyAegCard(card = null, index = null) {
    return {
        card: card,
        index: index,
        init() {},

        aegCard: {
            ['x-bind:style'](){
                let style_str = ``;
                switch(this.card.type) {
                    case 'blogCard' : 
                    case 'showcaseCard' :
                    case 'serviceCard' :   
                    case 'marketsCard' :
                    if((this.index + 1) % 3 == 0) {                       
                        style_str = `--img: url(${this.card.image.large}); background-size: 500px; background-repeat: no-repeat; background-position: 0px 0px;`;
                    }
                    if((this.index + 1) % 3 != 0) {
                        style_str = `--img: url(${this.card.image.small});background-repeat: no-repeat;background-position: top;background-size: 550px;`;
                    }
                    break;
                    case 'solutionsCard' :
                    if((this.index + 1) % 3 == 0) {                       
                        style_str = `--img: url(${this.card.image.large}); background-size: cover; background-repeat: no-repeat; background-position: 0px 0px;`;
                    }
                    if((this.index + 1) % 3 != 0) {
                        style_str = `--img: url(${this.card.image.small})`;
                    }
                    break;
                    case 'ctaCard' :
                    if((this.index + 1) % 3 == 0) {                       
                        style_str = `--img: url(${this.card.image.large})`;
                    }
                    if((this.index + 1) % 3 != 0) {
                        style_str = `--img: url(${this.card.image.small}); background-size: cover;`;
                    }
                    break;
                }

                return style_str;
            },
            ['x-bind:class']() {
                let card_str = ``;
                let size_str = ``;

                if((this.index + 1) % 3 == 0) {
                    size_str = 'MyAEGCard--lg ';
                }

                if((this.index + 1) % 3 != 0) {
                    size_str = 'MyAEGCard--sm ';
                }
                
                switch(this.card.type) {
                    case 'blogCard' : 
                        card_str = `MyAEGCard-blog`;
                    break;
                    case 'serviceCard' : 
                        card_str = `MyAEGCard-ctaService`;
                    break;
                    case 'serviceSecondaryCard' : 
                        card_str = `MyAEGCard-ctaService2`;
                    break;
                    case 'solutionsCard' : 
                        card_str = `MyAEGCard-ctaSolution`;
                    break;
                    case 'statCard' : 
                        card_str = `MyAEGCard-stat`;
                    break;
                    case 'downloadCard' : 
                        card_str = `MyAEGCard-download`;
                    break;
                    case 'ctaCard' : 
                        card_str = `MyAEGCard-cta`;
                    break;
                    case 'leadCard' : 
                        card_str = `MyAEGCard-generalLead`;
                    break;
                    case 'marketsCard' : 
                        card_str = `MyAEGCard-markets`;
                    break;
                    case 'showcaseCard' : 
                        card_str = `MyAEGCard-showcase`;
                    break;
                }

                return `${size_str} ${card_str}`;
            }
        },

        cardImage() {
            switch(card.type) {
                case 'blogCard' :
                case 'showcaseCard' :
                    return true;
                default: 
                    return;
            }
        },

        cardImg: {
            ['x-show']() {
                switch(card.type) {
                    case 'blogCard' :
                    case 'showcaseCard' :
                        return true;
                    default:
                        return;
                }
            }
        },

        cardDate: {
            ['x-show']() {
                return card.type == 'blogCard';
            },

            ['x-text']() {
                return card.postDate;
            }
        },

        moreBtn: {
            ['x-bind:class']() {
                let class_str = '';

                switch(card.type) {
                    case 'blogCard' :
                    case 'showcaseCard' :
                    case 'serviceSecondaryCard' :
                        class_str = 'btn btn--text text-red pt-0';
                    break;
                    case 'downloadCard' :
                        class_str = 'btn btn-arrow--download';
                    break;
                }

                return class_str;
            }
        },

        moreBtnText: {
            ['x-text']() {
                let str = 'Learn more';

                switch(card.type) {
                    case 'blogCard' :
                    case 'showcaseCard' :
                        return 'Read more';

                    case 'solutionsCard' :
                    case 'ctaCard' :
                        return 'Learn more';

                    case 'downloadCard' :
                        return 'Download';

                    case 'serviceCard' :
                    case 'serviceSecondaryCard' :
                    case 'marketsCard' :
                        return 'Find out more';

                    case 'leadCard' :
                        return 'Let\'s get started';

                    default:
                        return;
                }
            }
        },

        cardClick(e) {
            // return (card.type == 'leadCard') ? this.$store.myAEG.finalFormOpen = true : null;
            if(card.type != 'leadCard') {
                return; 
            } else {
                e.preventDefault();
                this.$store.myAEG.set('userChoice', card.leadForm);
                this.$store.myAEG.set('userChoiceLabel', this.$store.myAEG.forms[card.leadForm].label);
                this.$store.myAEG.set('userChoiceText', this.$store.myAEG.forms[card.leadForm].description);
                this.$store.myAEG.finalFormOpen = true;
            }
        },

        debuggery(index, type) {
            return `${index} - ${type}`;
        }
    }
}
