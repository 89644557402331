export default function Nav(){
    return {
        dropdownHeight: '400px',
        dropdownWidth: '400px',
        activeDropdown: false,
        activeDropdownPos: 0,
        hamburger: false,
        isMobile: window.matchMedia('(max-width: 768px)').matches,
        noDrop:{
            ['@mouseenter']($event){
                if(!this.isMobile){
                    //console.log('click', $event);
                    this.close()
                }
            },
        },
        navBtn: {
            ['@click.prevent']($event){
            //    console.log('click', $event);
               this.open($event.target.getAttribute('href'), $event)
            },
            ['@mouseenter']($event){
                if(!this.isMobile){
                    this.open($event.target.getAttribute('href'), $event)
                }
            },
            ['@click']($event){
                console.log('click', $event);
                this.open($event.target.getAttribute('href'), $event)
            },
            [':class'](){
                return this.activeDropdown == this.$el.getAttribute('href').substring(1) && 'is-open';
            }
        },
        open(name, {target}){
            this.activeDropdown = name.substring(1);

            const dropdown = document.querySelector(name);
            // console.log(dropdown,dropdown.clientHeight,dropdown.clientWidth);
            this.dropdownHeight = dropdown.clientHeight + 'px';
            this.dropdownWidth = dropdown.clientWidth + 'px';
            // this.dropdownLeft = target.getBoundingClientRect().x + target.clientWidth/2;
            
            this.dropdownLeft = target ? target.getBoundingClientRect().x + target.clientWidth/2 : 0;

            this.$nextTick(()=>{
                
                this.activeDropdownPos = target.getBoundingClientRect();

                const adjuster = 40;
                this.dropdownTop = (this.isMobile) ? this.activeDropdownPos.top + adjuster : this.activeDropdownPos.top;
                // dropdown.removeAttribute('hidden');
                // let targy = target;
                
            })

            
        },
        close(){
            // alert('is this working?')
            this.$nextTick(()=>{     
                this.activeDropdown = false;
                this.activeDropdownPos = this.$el.getBoundingClientRect();
            })
        },
        dropdownWrap:{
            ['x-bind:class'](){
                return { 'is-hidden' : !this.activeDropdown };
            }
        },
        dropdownNav:{
            ['x-bind:style'](){
                return `
                    --dropdownHeight: ${this.dropdownHeight};
                    --dropdownWidth: ${this.dropdownWidth}; 
                    --dropdownLeft: ${this.dropdownLeft}px;                    
                    --dropdownTop: ${this.dropdownTop}px;
                `
            },
            ['@mouseleave'](){
                if(!this.isMobile){
                    this.close()
                }
            }
        },

        hamburgerBtn:{
            ['@click'](){
                this.hamburger = !this.hamburger;
                this.close();
            }
        },

        dropdownMenuThing: {
            ['x-bind:class']() {
                return { 'hidden' : (!this.activeDropdown && this.isMobile)  };
            }
        }

    }
}

