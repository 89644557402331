import { ApolloClient, InMemoryCache, gql } from '@apollo/client/core';

var baseUrl = `${SITE_URL}myaegql`;


const client = new ApolloClient({
    uri: baseUrl,
    cache: new InMemoryCache()
});



export default function MyAeg() {
    return {
        active: false,
        name: this.$store.myAEG.name,
        body: document.getElementsByTagName('body')[0],
        cards: [],
        loading: true,
        hideFlash: true,
        state: {
            count: 0,
            name: ''
        },
        isMobile: window.matchMedia('(max-width: 1024px)').matches,

        async init() {
            this.$nextTick(() => {

                this.$store.myAEG.checkData().then((bounce) => {
                    // console.log(`bounce: ${bounce}`);
                    if(bounce) {
                        window.location = '/my-aeg/';
                    }
                });

            });
            
            this.$watch('active', (active) => (active == true) ? this.body.style.overflow = 'hidden' : this.body.style.overflow = 'auto')
            
            await client
                .query({
                    query: gql`
                        query MyQuery {
                            entryCount(section: "partnerCompanies", relatedTo: "${this.$store.myAEG.location.id}")
                            category(id: "${this.$store.myAEG.location.id}") {
                                title
                            }
                        }`
                })
                .then(data => {
                    console.log(`stateData --------------------------`)
                    console.log(data);
                    this.state.count = data.data.entryCount;
                    this.state.name = data.data.category.title;
                });

            

            await this.getCards();
        },

        async getCards () {
            const ids=[]
            if(this.$store.myAEG.iama.id){
                ids.push(this.$store.myAEG.iama.id)
            }
            if(this.$store.myAEG.interest.id){
                ids.push(this.$store.myAEG.interest.id)
            }
            if(this.$store.myAEG.location.id){
                ids.push(this.$store.myAEG.location.id)
            }
            const idList=ids.join(',')
            client
                .query({
                    query: gql`
                    query MyQuery {
                        entries(section: "myaegCards", relatedTo: ["or", ${idList}]) {
                            title
                            typeHandle
                            ... on myaegCards_myAEG_serviceCard_Entry {
                                id
                                priority
                                text:myAEG_card_excerpt
                                url:myAEG_card_relatedService {
                                    url
                                }
                                myAEG_card_icon {
                                    url
                                }
                                myAEG_card_largeImage {
                                    url
                                }
                                myAEG_card_smallImage {
                                    url
                                }
                            }
                            title
                            typeHandle
                            ... on myaegCards_myAEG_serviceSecondaryCard_Entry {
                                id
                                priority
                                text:myAEG_card_excerpt
                                url:myAEG_card_relatedService {
                                    url
                                }
                                myAEG_card_icon {
                                    url
                                }
                            }
                            ... on myaegCards_myAEG_marketsCard_Entry {
                                id
                                priority
                                text:myAEG_card_excerpt
                                url:myAEG_card_relatedMarket {
                                    url
                                }
                                myAEG_card_largeImage {
                                    url
                                }
                                myAEG_card_smallImage {
                                    url
                                }
                            }
                            ... on myaegCards_myAEG_solutionsCard_Entry {
                                id
                                priority
                                text:myAEG_card_excerpt
                                url:myAEG_card_relatedSolution {
                                    url
                                }
                                myAEG_card_largeImage {
                                    url
                                }
                                myAEG_card_smallImage {
                                    url
                                }
                            }
                            ... on myaegCards_myAEG_leadCard_Entry {
                                id
                                priority
                                secondTitle:myAEG_card_secondLine
                                subtitle:myAEG_card_subtitle
                                leadForm:myAEG_card_leadForm
                                myAEG_card_icon {
                                    url
                                }
                            }
                            ... on myaegCards_myAEG_blogCard_Entry {
                                id
                                priority
                                text:blogContent
                                url:myAEG_card_relatedBlog {
                                    url
                                }
                                relatedPostDate: myAEG_card_relatedBlog{
                                  postDate @formatDateTime (format: "M d, Y")
                                }
                                myAEG_card_relatedBlog {
                                    ... on blog_blogEntry_Entry {
                                        heroImage {
                                            url
                                        }
                                    }
                                }
                            }
                            ... on myaegCards_myAEG_showcaseCard_Entry {
                                id
                                priority
                                text:myAEG_card_excerpt
                                myAEG_card_largeImage {
                                    url
                                }
                                myAEG_card_smallImage {
                                    url
                                }
                                url:myAEG_card_relatedShowcase {
                                    url
                                }
                                relatedPostDate: myAEG_card_relatedShowcase{
                                  postDate @formatDateTime (format: "M d, Y")
                                }
                                myAEG_card_relatedShowcase {
                                    ... on caseStudies_caseStudy_Entry {
                                        heroImage {
                                            url
                                        }
                                    }
                                }
                            }
                            ... on myaegCards_myAEG_ctaCard_Entry {
                                id
                                priority
                                subtitle:myAEG_card_subtitle
                                text:myAEG_card_excerpt
                                url:myAEG_card_relatedEntry {
                                    url
                                }
                                myAEG_card_largeImage {
                                    url
                                }
                                myAEG_card_smallImage {
                                    url
                                }
                            }
                            ... on myaegCards_myAEG_downloadCard_Entry {
                                id
                                priority
                                url:myAEG_card_download {
                                    url
                                }
                                myAEG_card_largeImage {
                                    url
                                }
                                myAEG_card_smallImage {
                                    url
                                }
                            }
                            ... on myaegCards_myAEG_statCard_Entry {
                                id
                                priority
                                url:myAEG_card_relatedEntry {
                                    url
                                }
                                subtitle:myAEG_card_subtitle
                                stat:myAEG_card_stat
                                statTitle:myAEG_card_statTitle
                            }
                        }
                      }
                    `
                })
                .then(data => {
                    // console.log(data.data.entries);

                    this.cards = data.data.entries.map( function (item) {
                        
                        let card = {
                            title: item.title,
                            type: item.typeHandle.replace('myAEG_', ''),
                            priority: (item.priority) ? Number(item.priority) : (Math.random(9) + 1),

                            relatedService: item.myAEG_card_relatedService ? item.myAEG_card_relatedService[0] : false,
                        };

                        card.url = item.url ? item.url[0].url : '#';

                        card.cardText = item.text ? item.text.replace('<p>', '').replace('</p>', '') : '';
                        card.postDate = item.relatedPostDate ? item.relatedPostDate[0].postDate : '';
                        card.cardSubtitle = item.subtitle ;
                        card.cardStat = item.stat ;
                        card.secondTitle = item.secondTitle ;
                        card.cardStatTitle = item.statTitle ;
                        card.leadForm = item.leadForm ? item.leadForm : false;

                        let cardIcon = '';
                        
                        switch(card.type) {
                            case 'leadCard' : 
                            case 'serviceCard' :
                            case 'serviceSecondaryCard' :  
                                cardIcon = item.myAEG_card_icon[0].url;
                            break;
                            default:
                                cardIcon = '';
                            break;
                        }

                        card.icon = cardIcon

                        let lgImg = '', smImg = '';
                        
                        switch(card.type) {
                            case 'blogCard' : 
                                lgImg = item.myAEG_card_relatedBlog[0].heroImage[0].url;
                                smImg = item.myAEG_card_relatedBlog[0].heroImage[0].url;
                            break;
                            case 'leadCard' :
                            case 'statCard' :
                            case 'serviceSecondaryCard' :
                                lgImg = '';
                                smImg = '';
                            break;
                            default:
                                lgImg = item.myAEG_card_largeImage[0].url;
                                smImg = item.myAEG_card_smallImage[0].url;
                            break;
                        }
                        
                        card.image = {
                            large: lgImg,
                            small: smImg
                        }

                        return card; 
                    });

                    this.cards.sort((a, b) => {
                        if (a.priority < b.priority) {
                          return -1;
                        }
                      
                        if (a.priority > b.priority) {
                          return 1;
                        }
                      
                        return 0;
                    });

                    this.loading = false;
                })
                .catch(error => {
                    console.log(error);
                });
        },

        desktopSidebar: {
            ['x-bind:class']() {
                return (window.matchMedia('(max-width: 768px)').matches) ? 'MyAEGDashboard-desktopSidebar--mobile' : '';
            }
        },
    }
}
