//Alpine.js for the heavy lifting
import Alpine from 'alpinejs'
import intersect from '@alpinejs/intersect'
import collapse from '@alpinejs/collapse'
import persist from '@alpinejs/persist'
import focus from '@alpinejs/focus'

//Carousels
import Flickity from 'flickity';
require( 'flickity-as-nav-for' );
require( 'flickity-imagesloaded' );

//Timer
import Timer from "easytimer.js";

//Local script for nav
import Nav from  './nav';

//Local script for myAEG
import MyAeg from  './myaeg';
import MyAegCard from './myaegcard';

//Video player styling
import Plyr from 'plyr';
import { fieldNameFromStoreName } from '@apollo/client/cache'

//Script for Team
import Team from './team'

var Gauge = require("svg-gauge");

window.Flickity = Flickity;
window.Alpine = Alpine
Alpine.plugin(intersect);
Alpine.plugin(collapse);
Alpine.plugin(persist);
Alpine.plugin(focus);


document.addEventListener('alpine:init', () => {

    Alpine.data('nav', Nav); 

    Alpine.directive('timer',(el, { value, modifiers, expression }, { Alpine, evaluate, cleanup }) => {
        const timer = new Timer(); 
        timer.start({
            countdown: true, 
            startValues: {seconds: modifiers.length ? modifiers[0] : 5}
        });

        timer.addEventListener('targetAchieved', (e)=>{ el.dispatchEvent(new CustomEvent('targetachieved', e)) } );
        
        cleanup(() => {
            timer.removeEventListener('targetAchieved');
        })
    });

    Alpine.directive('flickity',(el, { value, modifiers, expression }, { Alpine, evaluate, cleanup }) => {
        const flickity = new Flickity(el,evaluate(expression));
    });

    Alpine.magic('flickity', (el, { Alpine }) => {return Flickity });

    Alpine.directive('gauge', (el, {value, modifiers, expression}, {Alpine, evaluate, cleanup}) => {
        console.log(el,value,modifiers,expression, evaluate(expression));
        let animate = modifiers.length;
        const options = evaluate(expression);
        // Create a new Gauge
        var myGauge = Gauge(el, {
            max: 100,
            dialStartAngle:160,
            dialEndAngle:20,
            color: () => options.color,
            // custom label renderer
            label: ()=>{ return options.value + "%"},
            value: animate ? 0 : options.value,
            radius: 100,
            viewBox: '0 0 100 75'
        });

        if(animate){
            // Set value and animate (value, animation duration in seconds)
            myGauge.setValueAnimated(options.value, modifiers[0]);
        }
        
    });

    Alpine.directive('breakpoint', (el, { value, modifiers, expression }, { Alpine, evaluateLater, effect, cleanup }) => {  
        let mql = window.matchMedia(`(${value}-width: ${modifiers[0]}px)`);   
        
        // for initial value
        el.dispatchEvent(new CustomEvent('breakpoint', {
            detail: { matches: mql.matches },
            bubbles: true,
        })); 
            
        mql.addEventListener('change', e => {
            console.log(e)
            el.dispatchEvent(new CustomEvent('breakpoint', {
                detail: { matches: e.matches },
                bubbles: false,
            }));
        });
        
        cleanup(() => {
            mql.removeEventListener('change');
        });
    });

    Alpine.directive('plyr', (el, { value, modifiers, expression }, { Alpine, evaluate, effect, cleanup }) => {
        console.log('x-plyr');
        const player = new Plyr(el, expression ? evaluate(expression): {});
        console.log(player);
    });

    

});
const basicFields = ['firstName', 'lastName', 'email', 'message'];

Alpine.store('myAEG', {
    name: Alpine.$persist('').as('user_name'),
    iama: {
        id: Alpine.$persist('').as('user_iama_id'),
        title: Alpine.$persist('').as('user_iama_name')
    },
    interest: {
        id: Alpine.$persist('').as('user_interest_id'),
        title: Alpine.$persist('').as('user_interest_name')  
    },
    location: {
        id: Alpine.$persist('').as('user_location_id'),
        title: Alpine.$persist('').as('user_location_name')  
    },

    userRequest: Alpine.$persist('').as('user_req_value'),

    finalFormOpen: false,

    forms: {
        'healthCheck' : {
            label: 'Health check my building',
            formFlash: 'Health check',
            description: 'Need to know if there are any issues with your elevators? Fill out the details below and someone from our team will be in touch.',
            fields: ['firstName', 'lastName', 'streetAddress', 'email', 'phoneNumber'],
        },
        'helpElevator' : {
            label: 'Help me with my elevator(s)',
            formFlash: 'Elevator help',
            description: 'Having an issue with your elevators? Please fill out the form below and we will be in touch as soon as possible.',
            fields: [...basicFields, 'streetAddress', 'phoneNumber'],
        },
        'getProposal' : {
            label: 'Get a proposal',
            formFlash: 'Proposal Request',
            description: 'Have a specific challenge or need you’d like for us to address? Send us the relevant details below and we’ll be in touch to discuss our proposal process and next steps.',
            fields: [...basicFields, 'streetAddress', 'phoneNumber', 'proposalType'],
        },
        'discussOpportunity' : {
            label: 'Discuss an opportunity',
            formFlash: 'Opportunity request',
            description: 'Have an opportunity you’d like to discuss with us? Please reach out below and we’ll be in touch.',
            fields: [...basicFields, 'phoneNumber'],
        },
        'urgentProblem' : {
            label: 'Have an urgent problem?',
            formFlash: 'Request',
            description: 'If you need immediate assistance, please fill out the form below and someone will be in touch with you as soon as possible.',
            fields: [...basicFields, 'streetAddress', 'phoneNumber'],
        },
        'becomePartner' : {
            label: 'Become a partner',
            formFlash: 'Partner request',
            description: 'We’re looking for the best in the business to join the AEG family. If this is your company, we’d love to hear from you.',
            fields: [...basicFields, 'companyName', 'streetAddress', 'phoneNumber'],
        },
        'joinTeam' : {
            label: 'Join our team',
            formFlash: 'Join request',
            description: 'Looking to grow your career in an exciting and challenging field? We’re always looking for passionate people to join our team. We can’t wait to hear from you.',
            fields: [...basicFields, 'phoneNumber'],
        },
        'requestCallback' : {
            label: 'Request a call back',
            formFlash: 'Call back request',
            description: 'Looking to speak to someone on the AEG team? Request a call back using the form below.',
            fields: [...basicFields, 'phoneNumber'],
        },
        'giveFeedback' : {
            label: 'Give your feedback',
            formFlash: 'Feedback request',
            description: 'Your voice is important to us and we want to hear from you. Let us know how we are doing and what we can do to improve.',
            fields: [...basicFields, 'phoneNumber'],
        },
        'signUp' : {
            label: 'Sign up to get our latest news',
            formFlash: 'Sign up request',
            description: 'Stay up to date on the latest news and resources from American Elevator Group and our partner companies.',
            fields: ['email'],
        },
    },
    userRequestLabel: '',
    userRequestText: '',

    set(which, value, id='') {
        switch (which) {
            case 'iama' :
                    this.iama.title = value;
                    this.iama.id = id;
                break;
            case 'interest' :
                    this.interest.title = value;
                    this.interest.id = id;
                break;

            case 'location' :
                    this.location.title = value;
                    this.location.id = id;
                break;
            case 'userChoice':
                    this.userRequest = value;
                break;
            case 'userChoiceLabel':
                    this.userRequestLabel = value;
                break;
            case 'userChoiceText': 
                    this.userRequestText = value;
                break;
            default: 
                return;
        }
    },

    checkData() {
        return new Promise((resolve) => {
            if(this.iama.title != '' 
            && this.interest.title != '' 
            && this.location.title != '' 
            && this.name != '') {
                // return false;
                resolve(false);
            } else {
                resolve(true);
            }
        });
    },
    
    validate() {
        if(this.iama.title != '' 
            && this.interest.title != '' 
            && this.location.title != '' 
            && this.name != '') {

                window.location = '/my-aeg/dashboard';

        }
    },

    checkField(fieldId) {
        return this.forms[this.userRequest].fields.includes(fieldId);
    },

    hiddenFieldVal(fieldId) {
        switch(fieldId) {
            case 'i_m_interested_in' :
                return this.interest.title;

            case 'i_am_a' :
                return this.iama.title;

            case 'state' :
                return this.location.title;

            case 'leadCard' : 
                return this.userRequestLabel;
        }
    },

    firstName(fieldId) {
        return (fieldId == 'firstName') ? this.name : null;
    },

});

Alpine.data('MyAeg', MyAeg); 
Alpine.data('MyAegCard', MyAegCard); 

Alpine.start();


 jQuery(document).ready(function(){
 	setTimeout(function() { 
 		jQuery('.Dropdown-wrap').removeClass('hide');
 	}, 500);
 });